// Here you can add other styles
@import "variables";

jcs-dynamic-overflow-menu {
  .overlay-trigger {
    button {
      font-size: 5px !important;
      line-height: 3px !important;
    }
  }
}

h1, h2, h3, mat-card-title {
  color: $headline-font-color;
}

.footer{
  background-color: $body-background-color;
  border-color: $body-background-color;
}


.cdk-overlay-container {
  z-index: 2000;
}

.cdk-overlay-container {
  z-index: 2000;
}

.headline {
  font-family: $font-family-headline;
}

.mat-mdc-card-subtitle {
  margin-top: 0 !important;
  font-weight: 400;
}

.mat-mdc-card-header {
  padding: 8px 16px !important;
}

.mat-tree-node {
  min-height: 35px;

  .mat-icon {
    display: inline;
  }

  .mat-mdc-icon-button {
    height: 35px;
    padding: 0px;
  }
}

.asset-common-container .mdc-radio {
  align-self: start !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

/** workaround for material/table matNoDataRow bug in v15 **/
tr.mat-row {
  height: 52px;

  td.mat-cell {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}

/** FONTS START****

/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff') format('woff'),
    /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff') format('woff'),
    /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff') format('woff'),
    /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Roboto') format('svg');
  /* Legacy iOS */
}

/* open-sans-300 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-300.woff') format('woff'),
    /* Modern Browsers */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff') format('woff'),
    /* Modern Browsers */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-500 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-500.woff') format('woff'),
    /* Modern Browsers */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../assets/fonts/OpenSans/open-sans-v34-vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* material icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/MaterialIcons/MaterialIcons-Regular.eot');
  /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('../assets/fonts/MaterialIcons/MaterialIcons-Regular.woff2') format('woff2'),
  url('../assets/fonts/MaterialIcons/MaterialIcons-Regular.woff') format('woff'),
  url('../assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/MaterialIcons/MaterialIconsOutlined-Regular.otf');
}

/** FONTS END****/

.mat-mdc-fab, .mat-mdc-mini-fab {
  background-color: $primary-color !important;
  color: #fff !important;
}

.mat-lancrypt-primary:not(:disabled) {
  background-color: $primary-color !important;
  color: #fff !important;
}

.mat-lancrypt-primary-light:not(:disabled) {
  background-color: #fff !important;
  color: $primary-color !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $primary-color;
  color: #fff;
}

/** radio buttons styles **/
mat-radio-button {
  --mdc-radio-selected-focus-icon-color: #{$primary-color} !important;
  --mdc-radio-selected-hover-icon-color: #{$primary-color} !important;
  --mdc-radio-selected-icon-color: #{$primary-color} !important;
  --mdc-radio-selected-pressed-icon-color: #{$primary-color} !important;
  --mat-mdc-radio-checked-ripple-color: #{$primary-color} !important;
}

/** checkboxes styles **/
mat-checkbox {
  --mdc-checkbox-selected-focus-icon-color: #{$primary-color} !important;
  --mdc-checkbox-selected-hover-icon-color: #{$primary-color} !important;
  --mdc-checkbox-selected-icon-color: #{$primary-color} !important;
  --mdc-checkbox-selected-pressed-icon-color: #{$primary-color} !important;
}

mat-checkbox.mat-mdc-checkbox-checked, mat-checkbox[ng-reflect-indeterminate="true"] {
  .mdc-checkbox__ripple {
    background: $primary-color !important;
  }

  .mat-ripple-element {
    background-color: rgba($primary-color, 0.1) !important;
  }
}

/** slider style **/
mat-slider {
  --mdc-slider-active-track-color: #{$primary-color} !important;
  --mdc-slider-handle-color: #{$primary-color} !important;
  --mdc-slider-focus-handle-color: #{$primary-color} !important;
  --mdc-slider-label-container-color: #{$primary-color} !important;
  --mat-mdc-slider-value-indicator-opacity: #{1} !important;
}

/** tooltip style **/
mat-tooltip-component .mdc-tooltip__surface {
  background-color: #{$primary-color} !important;
}

/** always show a margin between two panels in an accordion, even when they are collapsed **/
mat-accordion {
  .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
    margin-bottom: 16px;
  }
}

/** toggle switch style **/
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-handle-color: #{$primary-color} !important;
  --mdc-switch-selected-focus-state-layer-color: #{$primary-color} !important;
  --mdc-switch-selected-focus-track-color: #{$primary-color} !important;
  --mdc-switch-selected-handle-color: #{$primary-color} !important;
  --mdc-switch-selected-hover-handle-color: #{$primary-color} !important;
  --mdc-switch-selected-hover-state-layer-color: #{$primary-color} !important;
  --mdc-switch-selected-hover-track-color: #{$primary-color} !important;
  --mdc-switch-selected-pressed-handle-color: #{$primary-color} !important;
  --mdc-switch-selected-pressed-state-layer-color: #{$primary-color} !important;
  --mdc-switch-selected-pressed-track-color: #{$primary-color} !important;
  --mdc-switch-selected-track-color: #{$primary-color} !important;
}

.primary-color {
  background-color: $primary-color;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

input[type="password"]::-webkit-caps-lock-indicator {
  margin-right: 2.2em;
}

input[type="password"]::-webkit-credentials-auto-fill-button {
  margin-bottom: 0.8em;
}

.bg-light {
  background-color: $body-background-color !important;
}
