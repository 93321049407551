$font-color-primary: #3C3C3C;
$font-color-secondary: #8A9CA4;
$highlight-color: #0068B4;
$primary-color: #0068B4;
$table-active-color: #ECEEF0;

$avatar-icon-color: #EAF6FE;
$avatar-text-color: #3C3C3C;
$header-background-color: #0068B4;
$header-text-color: #EAF6FE;
$side-menu-color: #EAF6FE;

$menu-font-color: #0068B4;

$body-background-color: white;

$headline-font-color: #0068B4;

$font-family-sans-serif: Roboto;
$font-family-headline: "Open Sans";
